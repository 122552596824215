import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import UnorderedList from "~/components/List/Unordered";

const Page = () => {
  return (
    <LessonLayout
      current_step={8}
      lesson={"Lesson 4"}
      color={"hs"}
      total_count={15}
      module_title="What Could Happen?"
    >
      <ContentWrapper>
        <H2 underline>Step 8</H2>

        <p>You have probably gone into a drugstore or grocery store and have seen things like cold medicines or pain relievers that you can buy without a prescription. These are called over-the-counter (OTC) medications.</p>
        <p>Think back to the second commercial you watched earlier about Culturelle. You probably noticed that the commercial gave less information than the Stelara commercial. The second commercial is for a medication that does not require a prescription from a doctor.</p>

        <div className="mt-5 w-2/3 flex content-end">
          <StaticImage
            alt="Hand using tablet."
            className="rounded"
            src="../../../images/lesson_4_step_8.jpg"
            loading="eager"
            placeholder="blurred"
            style={{
              filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
            }}
          />
        </div>

        <p>OTC medicines are safe when used as directed on the package. There are some differences between prescription and OTC medicines.</p>

        <UnorderedList>
          <li>OTC medicines need to follow rules set by the FDA. The rules include information about what is in the drug, the dosage, the safety information, and guidance for who should or should not use the drug.</li>
          <li>As long as an OTC drug meets these rules, the FDA does not review or approve the medication.</li>
          <li>If the OTC drug includes a new chemical or does not “fit” into the rules, then the company needs to work with the FDA to get the new OTC drug approved.</li>
        </UnorderedList>

      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

